import { Breadcrumb, Table, Tabs } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import {
  getProposalsList,
  getSecondaryProposalsList,
} from "../../../redux/_actions";
import { toast } from "../../Ui/Toast/Toast";
import Paginate from "../../Ui/Paginate/Paginate";
import debounce from "lodash.debounce";
import { logout_on_deleted } from "../../../App";

let paginationData = "";

function WhiteListAddresses() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal_name",
      key: "proposal_name",
    },
    {
      title: "Token Name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Type of Proposal",
      dataIndex: "proposal_type",
      key: "proposal_type",
    },
    {
      title: "Total Token Quantity",
      dataIndex: "token_quantity",
      key: "token_quantity",
    },
    {
      title: "Consumed Token Quantity",
      dataIndex: "token_quantity_consume",
      key: "token_quantity_consume",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const columnsSecondary = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Proposal Name",
      dataIndex: "proposal_name",
      key: "proposal_name",
    },
    {
      title: "Seller Email",
      dataIndex: "seller_email",
      key: "seller_email",
    },
    {
      title: "Token Name",
      dataIndex: "token_name",
      key: "token_name",
    },
    {
      title: "Type of Proposal",
      dataIndex: "proposal_type",
      key: "proposal_type",
    },
    {
      title: "Total Token Quantity",
      dataIndex: "token_quantity",
      key: "token_quantity",
    },
    {
      title: "Consumed Token Quantity",
      dataIndex: "token_quantity_consume",
      key: "token_quantity_consume",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  const limit = 10;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [ProposalList, setProposalList] = useState([]);
  const [secondaryProposalList, setSecondaryProposalList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState("");
  const [activeTab, setActiveTab] = useState("Primary");

  useEffect(() => {
    activeTab === "Primary"
      ? fetchProposalList()
      : fetchSecondaryProposalList();
  }, [activeTab, pageCount, search]);

  /************************************** fetchProposalList ****************************************************/
  const fetchProposalList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
    };
    setTimeout(() => {
      dispatch(getProposalsList({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows;
          console.log("PROPOSAL LIST RESPONSE", res);

          if (response?.data?.data?.count > 0) {
            res.forEach((item, index) => (item["key"] = index + 1));
            setProposalList(res);
          } else {
            setProposalList([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          if (err === "Unauthorized access. Please login to continue") {
            toast.error("Session expired!");
            setTimeout(() => {
              logout_on_deleted();
            }, 200);
            setLoading(false);
          } else {
            toast.error(err);
            setLoading(false);
          }
          setLoading(false);
        });
    }, 100);
  };

  const fetchSecondaryProposalList = () => {
    setLoading(true);
    const data = {
      limit: limit,
      page: pageCount,
    };
    setTimeout(() => {
      dispatch(getSecondaryProposalsList({ data }))
        .then((response) => {
          const res = response?.data?.data?.rows;
          console.log("PROPOSAL LIST RESPONSE", res);

          if (response?.data?.data?.count > 0) {
            res.forEach((item, index) => (item["key"] = index + 1));
            setSecondaryProposalList(res);
          } else {
            setSecondaryProposalList([]);
          }
          paginationData = response?.data?.meta?.pages || 0;
          setLoading(false);
        })
        .catch((err) => {
          if (err === "Unauthorized access. Please login to continue") {
            toast.error("Session expired!");
            setTimeout(() => {
              logout_on_deleted();
            }, 200);
            setLoading(false);
          } else {
            toast.error(err);
            setLoading(false);
          }
          setLoading(false);
        });
    }, 100);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    setPageCount(1); // Reset page count to 1 on tab change
  };

  /************************************** table data ****************************************************/
  const dataSourcePrimary = ProposalList.map((item, index) => ({
    key: item.key,
    sno: (
      <div style={{ marginLeft: 15 }} className="tableIconStyle ">
        <span className="fontFamilyText">
          {(pageCount - 1) * limit + (index + 1)}
        </span>
      </div>
    ),
    proposal_name: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">{item?.proposal_name}</span>
      </div>
    ),

    token_name: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">{item?.token_name}</span>
      </div>
    ),
    proposal_type: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">
          {item?.asset_type?.type_of_proposal}
        </span>
      </div>
    ),
    token_quantity: (
      <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
        <span className="fontFamilyText">{item?.total_token_quantity}</span>
      </div>
    ),
    token_quantity_consume: (
      <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
        <span className="fontFamilyText">
          {item?.consumed_token_quantity || "0.00"}
        </span>
      </div>
    ),
    action: (
      <Link
        to={"/whiteListAddresses/whiteListDetail"}
        style={{ marginLeft: -25 }}
        state={{
          id: item.id,
          proposal_name: item.proposal_name,
          projectid: item.project_id,
        }}
        className="buttonView"
      >
        <span className="fontFamilyText">
          View <EyeOutlined />
        </span>
      </Link>
    ),
  }));

  const dataSourceSecondary = secondaryProposalList.map((item, index) => ({
    key: item.key,
    sno: (
      <div style={{ marginLeft: 15 }} className="tableIconStyle ">
        <span className="fontFamilyText">
          {(pageCount - 1) * limit + (index + 1)}
        </span>
      </div>
    ),
    proposal_name: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">
          {item?.secondary_proposal_data?.title}
        </span>
      </div>
    ),
    seller_email: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">{item?.seller_email}</span>
      </div>
    ),
    token_name: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">
          {item?.secondary_proposal_data?.token_name}
        </span>
      </div>
    ),
    proposal_type: (
      <div className="tableIconStyle">
        <span className="fontFamilyText">
          {item?.secondary_proposal_data?.asset_type?.type_of_proposal}
        </span>
      </div>
    ),
    token_quantity: (
      <div style={{ marginLeft: 55, textTransform: "capitalize" }}>
        <span className="fontFamilyText">{item?.total_token_quantity}</span>
      </div>
    ),
    token_quantity_consume: (
      <div style={{ marginLeft: 65, textTransform: "capitalize" }}>
        <span className="fontFamilyText">
          {item?.consumed_token_quantity || "0.00"}
        </span>
      </div>
    ),
    action: (
      <Link
        to={"/whiteListAddresses/SecondaryWhitelistDetail"}
        style={{ marginLeft: -25 }}
        state={{
          id: item.id,
          proposal_name: item?.secondary_proposal_data?.title,
          proposal_id: item?.proposal_id,
          project_id: item?.secondary_proposal_data?.project_id,
        }}
        className="buttonView"
      >
        <span className="fontFamilyText">
          View <EyeOutlined />
        </span>
      </Link>
    ),
  }));

  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  /**************************************updateSearch****************************************************/
  const updateSearch = (search) => {
    setLocalValue(search);
    debounceData(search);
  };

  /**************************************debounceData****************************************************/
  const debounceData = useCallback(
    debounce((search) => {
      setSearch(search.trim());
      setLocalValue(search.trim());
      setPageCount(1);
    }, 1000),
    []
  );

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>WhiteList Addresses</Breadcrumb.Item>
        </Breadcrumb>
        <span>
          <b>Assigned Role : </b>
          {login?.admin_user_access_data
            ? login?.admin_user_access_data?.role_name
            : "Agent"}
        </span>
      </div>
      <div className="tabs__body">      <div className="commonPadding userDatils">
        <Tabs
          defaultActiveKey="Primary"
          onChange={handleTabChange}
          className="tabs"
        >
          <Tabs.TabPane tab="Primary" key="Primary">
            <Table
              dataSource={dataSourcePrimary}
              columns={columns}
              pagination={false}
              className="custom-scrollbar"
              size="large"
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Secondary" key="Secondary">
            <Table
              dataSource={dataSourceSecondary}
              columns={columnsSecondary}
              pagination={false}
              className="custom-scrollbar"
              size="large"
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      </div>

      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount === "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </>
  );
}

export default WhiteListAddresses;
