import { REACT_APP_DOMAIN_KEY } from "../../Services/EndPoints";
import { toast } from "../Ui/Toast/Toast";

const commonError = () => {
  toast.error("Session expired");
  setTimeout(() => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/" + REACT_APP_DOMAIN_KEY);
    // window.location.reload();
  }, 2000);
};

export default commonError;
