import { toast } from "../../Components/Ui/Toast/Toast";
import {
  apiCallGet,
  apiCallPost,
  apiCallPostLogin,
  apiCallProposalGet,
  apiCallProposalRequestPost,
  apiCallRequestPost,
} from "../../Services/ApiClient";
import {
  CHANGE_PASSWORD_API,
  GET_INVESTED_APP_LIST,
  GET_INVESTED_REQUESTS_LIST,
  GET_PROPOSAL_LIST,
  GET_USER_DATA,
  GET_USER_DETAILS_API,
  GET_USER_LIST,
  INVESTED_REQUESTS_APPROVE_REJECT,
  GET_INVESTED_MINTING_LIST,
  MINTING_STATUS_UPDATE,
  CHANGE_PASSWORD_AGENT,
  INVESTED_APP_REQUESTS_APPROVE_REJECT,
  GET_SECONDARY_ADDRESSES_LIST,
  GET_SECONDARY_PROPOSAL_LIST,
  GET_SECONDARY_INVESTED_REQUESTS_LIST,
} from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";

/************************************** getUserList ****************************************************/

/************************************** getUserData ****************************************************/

/************************************** changePassword ****************************************************/
export const changePassword = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPostLogin(CHANGE_PASSWORD_AGENT, data)
        .then((response) => {
          const res = response.data;
          //console.log('res changePassword::::::', res);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log(errorMessage, 'changePassword er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};
/************************************** logoutAdmin ****************************************************/
export const logoutAdmin = ({}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallGet(CHANGE_PASSWORD_AGENT)
        .then((response) => {
          const res = response.data;
          //console.log('res logoutAdmin::::::', res);
          resolve(res);
        })
        .catch((error) => {
          const errorMessage = error.message || error;
          //console.log('logoutAdmin er::::::', error);
          reject(errorMessage);
        });
    });
  };
};

/************************************** GetProposalList for agent ****************************************************/

export const getProposalsList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallProposalGet(GET_PROPOSAL_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getSecondaryProposalsList = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallRequestPost(GET_SECONDARY_PROPOSAL_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            console.log("getProposalsList er::::::", error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getInvestmentRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallProposalRequestPost(GET_INVESTED_REQUESTS_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getSecondaryInvestmentRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallRequestPost(GET_SECONDARY_INVESTED_REQUESTS_LIST, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const investmentRequestsAprovalRejection = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallRequestPost(INVESTED_REQUESTS_APPROVE_REJECT, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const investmentAppRequestsAprovalRejection = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallRequestPost(INVESTED_APP_REQUESTS_APPROVE_REJECT, data)
        .then((response) => {
          const res = response;
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

// export const mintingStatusUpdate = (data) => {
//   return (dispatch) => {
//     console.log("data in useraction", data);
//     return new Promise((resolve, reject) => {
//       apiCallRequestPost(MINTING_STATUS_UPDATE, data)
//         .then((response) => {
//           const res = response;
//           resolve(res);
//         })
//         .catch((error) => {
//           if (error?.message == "Unable to decode data.") {
//             commonError();
//           } else {
//             const errorMessage = error.message || error;
//             //console.log('getUserTxnDetails er::::::', error);
//             reject(errorMessage);
//           }
//         });
//     });
//   };
// };

export const getAppRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallProposalRequestPost(GET_INVESTED_APP_LIST, data)
        .then((response) => {
          const res = response.data;
          //console.log('res getUserTxnDetails::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};

export const getSecondaryWhitelistRequests = ({ data }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallProposalRequestPost(GET_SECONDARY_ADDRESSES_LIST, data)
        .then((response) => {
          const res = response.data;
          //console.log('res getUserTxnDetails::::::', response);
          resolve(res);
        })
        .catch((error) => {
          if (error?.message == "Unable to decode data.") {
            commonError();
          } else {
            const errorMessage = error.message || error;
            //console.log('getUserTxnDetails er::::::', error);
            reject(errorMessage);
          }
        });
    });
  };
};
