import { Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import {
  getAppRequests,
  getInvestmentRequests,
  investmentAppRequestsAprovalRejection,
  investmentRequestsAprovalRejection,
} from "../../../redux/_actions";
import { toast } from "../../Ui/Toast/Toast";

import Paginate from "../../Ui/Paginate/Paginate";
import Swal from "sweetalert2";
import { ButtonCustom } from "../../Ui/button/ButtonCustom";
import {
  addressWhitelistBatch,
  addressWhitelistSingle,
} from "../../../Services/web3/ContractServices";
import {
  BASE_URL,
  DOWNLOAD_INVESTED_APP_PROPOSAL_CSV,
  DOWNLOAD_INVESTED_PROPOSAL_CSV,
} from "../../../Services/EndPoints";

// let type = "Investment Requests";
let paginationData = "";
console.log("paginationData", paginationData);
function WhiteListTab(props) {
  const columns = [
    {
      title: "S.NO.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
    },
    {
      title: "Email Id",
      dataIndex: "ext_email_id",
      key: "ext_email_id",
    },
    {
      title: "User Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Action",
      dataIndex: "View",
      key: "View",
    },
  ];
  const appRequestsColumn = [
    {
      title: "S.NO.",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
    },

    {
      title: "User Address",
      dataIndex: "address",
      key: "address",
    },

    {
      title: "Action",
      dataIndex: "View",
      key: "View",
    },
  ];
  console.log("paginationData", paginationData);

  const { TabPane } = Tabs;
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState("");
  const [limit, setlimit] = useState(10);
  const [investmentRequests, setInvestmentRequests] = useState();
  const [appRequests, setAppRequests] = useState();

  const [type, setType] = useState("Investment Requests"); // Use state to manage the type
  const [activeKey, setActiveKey] = useState("1");

  const project_id = location.state?.projectid;
  const id = location.state?.id;
  const proposal_name = location.state?.proposal_name;
  console.log(
    "project_id",
    project_id,
    "    proposal_name",
    proposal_name,
    "    id",
    id
  );

  useEffect(() => {
    if (type === "Investment Requests") {
      getProposalInvestmentRequests();
    } else if (type === "App Requests") {
      getInvestedAppRequests();
    }
  }, [type, pageCount]);

  const handleApproval = (userAddress, status) => {
    // proposal_id, approval_status, wallet_address
    const data = {
      proposal_id: id,
      approval_status: status,
      wallet_address: [userAddress],
    };
    console.log("data for approval", data);
    setTimeout(() => {
      setLoading(true);

      dispatch(investmentRequestsAprovalRejection({ data }))
        .then((response) => {
          getProposalInvestmentRequests();
          const res = response;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const handleAppRequestApproval = (walletId, status) => {
    const data = {
      wallet_id: walletId,
      status: status,
    };
    console.log("data for approval", data);
    setTimeout(() => {
      setLoading(true);

      dispatch(investmentAppRequestsAprovalRejection({ data }))
        .then((response) => {
          getInvestedAppRequests();
          const res = response;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  const handleRejection = (status, userAddress) => {
    const data = {
      proposal_id: id,
      approval_status: status,
      wallet_address: [userAddress],
    };
    setTimeout(() => {
      setLoading(true);

      dispatch(investmentRequestsAprovalRejection({ data }))
        .then((response) => {
          getProposalInvestmentRequests();

          const res = response;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const handleAppRejection = (status, walletId) => {
    const data = {
      wallet_id: walletId,
      status: status,
    };
    setTimeout(() => {
      setLoading(true);

      dispatch(investmentAppRequestsAprovalRejection({ data }))
        .then((response) => {
          const res = response;
          getInvestedAppRequests();

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };
  /**************************************fetchUserData****************************************************/
  const getProposalInvestmentRequests = () => {
    const data = {
      proposal_id: id,
      page: pageCount || 1,
      limit: limit,
    };
    setTimeout(() => {
      setLoading(true);

      dispatch(getInvestmentRequests({ data }))
        .then((response) => {
          const res = response?.data?.data?.investmentdata?.rows;
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setInvestmentRequests(res);
          } else {
            setInvestmentRequests();
          }
          paginationData = response?.data?.meta?.pages;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  const getInvestedAppRequests = () => {
    const data = {
      proposal_id: id,
      page: pageCount || 1,
      limit: limit,
    };
    setTimeout(() => {
      setLoading(true);

      dispatch(getAppRequests({ data }))
        .then((response) => {
          const res = response?.data?.rows;
          console.log("res", res);
          if (res.length > 0) {
            res.map((item, index) => (item["key"] = index + 1));
            setAppRequests(res);
          } else {
            setAppRequests();
          }
          console.log("response?.data?.meta?.pages", response?.meta?.pages);
          paginationData = response?.meta?.pages;
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  /************************************** handleTabChange ****************************************************/

  const handleTabChange = (key) => {
    setPageCount(1);
    setType(key == 1 ? "Investment Requests" : "App Requests"); // Update the type state based on the tab
    setActiveKey(key);
  };

  /************************************** App Requests Data ****************************************************/
  const appRequestsData = appRequests?.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {((pageCount ? pageCount : 1) - 1) * limit + (index + 1)}
          </span>
        </div>
      ),

      address: (
        <div className="tableIconStyle ">
          <span className="fontFamilyText">{item?.wallet_address}</span>
        </div>
      ),

      View:
        item.status === 0 ? (
          <>
            <div style={{ marginLeft: -22 }} className="agreementBtn">
              <ButtonCustom
                lightBtn
                label="Approve"
                // disabled={item.soft_cap_status !== 1} // Disable if soft_cap_status is not 1
                onClick={() => {
                  Swal.fire({
                    title: `Are you sure you want to approve?`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#d3d3d3",
                    confirmButtonText: "Yes",
                    cancelButtonText: "NO",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      // Call addressWhitelistSingle function here
                      try {
                        setLoading(true);
                        const result = await addressWhitelistSingle({
                          userAddress: item?.wallet_address,
                          projectId: project_id,
                        });
                        console.log("RESULT", result);
                        // Call handleApproval after successful execution of addressWhitelistSingle
                        if (result === "alreadyWhitelisted") {
                          handleAppRequestApproval(item?.wallet_id, 1);
                        } else if (result) {
                          // If the operation was successful, proceed as usual
                          handleAppRequestApproval(item?.wallet_id, 1);
                        }
                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        console.error(
                          "Error in addressWhitelistSingle:",
                          error.message
                        );
                      }
                    }
                  });
                }}
              />

              {/* <ButtonCustom
                yellowBtn
                label="Decline"
                onClick={() => {
                  Swal.fire({
                    title: `Decline`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#d3d3d3",
                    confirmButtonText: "Yes",
                    cancelButtonText: "NO",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleAppRejection(2, item?.wallet_id);
                    }
                  });
                }}
                // className={`declineBtn`}
              /> */}
            </div>
          </>
        ) : (
          <div style={{ marginLeft: -22 }}>
            {item?.status == 1
              ? "Approved"
              : item?.status == 2
              ? "Rejected"
              : ""}
          </div>
        ),
    };
  });
  /************************************** Investment Requests Data ****************************************************/
  const dataSource = investmentRequests?.map((item, index) => {
    return {
      key: item.key,
      sno: (
        <div style={{ marginLeft: 15 }} className="tableIconStyle ">
          <span className="fontFamilyText">
            {((pageCount ? pageCount : 1) - 1) * limit + (index + 1)}
          </span>
        </div>
      ),
      Username: (
        <div
          className="tableIconStyle "
          style={{ textTransform: "capitalize" }}
        >
          <span className="fontFamilyText">{item?.wallet_data?.username}</span>
        </div>
      ),
      ext_email_id: (
        <div className="tableIconStyle ">
          <span className="fontFamilyText">{item?.wallet_data?.email}</span>
        </div>
      ),
      address: (
        <div className="tableIconStyle ">
          <span className="fontFamilyText">{item?.user_adrs}</span>
        </div>
      ),

      View:
        item?.wallet_data?.approval_status === null ? (
          <>
            <div style={{ marginLeft: -22 }} className="agreementBtn">
              <button
                disabled={item.soft_cap_status !== 1} // Disable if soft_cap_status is not 1
                onClick={() => {
                  Swal.fire({
                    title: `Approve`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#d3d3d3",
                    confirmButtonText: "Yes",
                    cancelButtonText: "NO",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      // Call addressWhitelistSingle function here
                      try {
                        setLoading(true);
                        const result = await addressWhitelistSingle({
                          userAddress: item?.user_adrs,
                          projectId: project_id,
                        });
                        console.log("RESULT", result);
                        // Call handleApproval after successful execution of addressWhitelistSingle
                        if (result === "alreadyWhitelisted") {
                          handleApproval(item?.user_adrs, "approved");
                        } else if (result) {
                          // If the operation was successful, proceed as usual
                          handleApproval(item?.user_adrs, "approved");
                        }
                        setLoading(false);
                      } catch (error) {
                        setLoading(false);
                        console.error(
                          "Error in addressWhitelistSingle:",
                          error.message
                        );
                      }
                    }
                  });
                }}
              >
                Approve
              </button>
              {/* <button
                onClick={() => {
                  Swal.fire({
                    title: `Decline`,
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#d3d3d3",
                    confirmButtonText: "Yes",
                    cancelButtonText: "NO",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleRejection("declined", item?.user_adrs);
                    }
                  });
                }}
                className={`declineBtn`}
              >
                Decline
              </button> */}
            </div>
          </>
        ) : (
          <div style={{ marginLeft: -22 }}>
            {item?.wallet_data?.approval_status === "approved"
              ? "Approved"
              : "Rejected"}
          </div>
        ),
    };
  });
  /************************************** handlePageClick ****************************************************/
  const handlePageClick = (page) => {
    setPageCount(page.selected + 1);
  };

  const downoaldList = () => {
    const link =
      type == "Investment Requests"
        ? `${BASE_URL}${DOWNLOAD_INVESTED_PROPOSAL_CSV}?proposal_id=${id}`
        : `${BASE_URL}${DOWNLOAD_INVESTED_APP_PROPOSAL_CSV}?proposal_id=${id}`;
    window.open(link);
    return;
  };
  // `${BASE_URL}${DOWNLOAD_QUERIES_List}?search=${search}&status=${status}`

  const handleApproveAll = async () => {
    const confirmation = await Swal.fire({
      title: "Approve All",
      text: "Are you sure you want to approve all requests?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d3d3d3",
      confirmButtonText: "Yes",
      cancelButtonText: "NO",
    });

    if (confirmation.isConfirmed) {
      console.log("investmentRequests", investmentRequests);
      const pendingRequests = await investmentRequests.filter(
        (item) => item?.wallet_data?.approval_status === null
      );
      console.log("pendingRequests", pendingRequests);
      if (pendingRequests.length === 0) {
        // No pending requests found
        Swal.fire({
          title: "No Pending Requests",
          text: "There are no pending requests to approve.",
          icon: "info",
        });
        return;
      }

      const softCapStatus = pendingRequests[0].soft_cap_status;
      const userAddresses = [];
      const salts = [];
      const countries = [];
      // const amounts = [];
      // const projectId = [];
      // Extract user addresses and amounts
      pendingRequests.forEach((item) => {
        userAddresses.push(item.user_adrs);
        salts.push(Date.now() + Math.random().toString(36).substring(2));
        countries.push(91);

        // amounts.push(item.amount);
      });

      console.log(userAddresses, salts, countries);

      // Call addressWhitelistBatch function
      if (softCapStatus === 1) {
        try {
          const data = { userAddresses, project_id, salts, countries };
          setLoading(true);
          const result = await addressWhitelistBatch({
            data,
          });
          // Display success message

          if (result) {
            handleApproval(userAddresses, "approved");
            Swal.fire({
              title: "Success!",
              text: "All requests have been approved.",
              icon: "success",
            });
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(error.message);
          console.error("Error in addressWhitelistBatch:", error.message);
        }
      } else {
        toast.error(`Soft cap status not reached!`);
      }
    }
  };

  return (
    <div>
      <div className="tabs__body">
        <div className="addnewSec">
          {activeKey == 1 ? (
            <ButtonCustom
              onClick={handleApproveAll}
              label="Approve All"
              lytgaryBtn
            />
          ) : null}
          <ButtonCustom
            // onClick={() => alert("download csv")}
            onClick={() => downoaldList()}
            label="Download CSV"
            lytgaryBtn
          />
        </div>
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          // defaultActiveKey="1"
        >
          <TabPane tab="Investment Requests" key="1">
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              size="large"
            />
          </TabPane>
          <TabPane tab="App Requests" key="2">
            <Table
              dataSource={appRequestsData}
              columns={appRequestsColumn}
              pagination={false}
              size="large"
            />
          </TabPane>
        </Tabs>
      </div>
      {paginationData > 1 && (
        <Paginate
          paginationData={paginationData}
          forcePage={pageCount == "" ? 0 : pageCount - 1}
          handlePageClick={(page) => handlePageClick(page)}
        />
      )}
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default WhiteListTab;
