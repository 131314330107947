import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu, Grid } from "antd";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

import {
  DashboardIcon,
  DashboardIconLight,
  SettingIcon,
  SettingIconLight,
} from "../../Assets/Images/SidebarIcons";

import "./DashboardLayout.scss";
import useWindowDimensions from "../../Ui/useWindowDimensions/useWindowDimensions.jsx";

function getItem(label, key, icon, children) {
  return { key, icon, children, label };
}

function DashboardLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const { Content, Sider } = Layout;
  const width = useWindowDimensions();
  const [theme, setTheme] = useState();

  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);

  useEffect(() => {
    // Extract the key from the current route path
    const currentKey = location.pathname.split("/")[1];
    setDefaultSelectedKeys([currentKey]);
  }, [location.pathname]);

  /************************************** changeTheme ****************************************************/
  const changeTheme = (data) => {
    setTheme(data);
  };

  const items = [
    getItem(
      <Link to="/whiteListAddresses">
        <span className="fontFamilyText">Whitelist Addresses</span>
      </Link>,
      "whiteListAddresses",
      <span className="anticon anticon-desktop ant-menu-item-icon">
        {theme === "darkTheme" ? <DashboardIconLight /> : <DashboardIcon />}
      </span>
    ),

    // getItem(
    //   <Link to="/mintingAddresses">
    //     <span className="fontFamilyText ">Minting Addresses</span>
    //   </Link>,
    //   "mintingAddresses",
    //   <span className="anticon anticon-desktop ant-menu-item-icon ">
    //     {theme == "darkTheme" ? <DashboardIconLight /> : <DashboardIcon />}
    //   </span>
    // ),

    getItem(
      <Link to="/adminControls">
        <span className="fontFamilyText ">Admin Controls</span>
      </Link>,
      "adminControls",
      <span className="anticon anticon-desktop ant-menu-item-icon ">
        {theme == "darkTheme" ? <SettingIconLight /> : <SettingIcon />}
      </span>
    ),
  ];
  return (
    <>
      <Header changeTheme={changeTheme} />
      <Layout className={width < 991 ? "dashboardSidebarOuter" : ""}>
        <Sider
          width="250px"
          collapsedWidth="100px"
          onCollapse={(value) => setCollapsed(value)}
          className={`overFlow ${width < 991 ? "dashboardSidebar" : ""}`}
        >
          {/* <Menu
            theme="dark"
            defaultSelectedKeys={[key]}
            mode="inline"
            items={items}
          /> */}
          <Menu theme="dark" mode="inline" selectedKeys={defaultSelectedKeys}>
            {items.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                // className={`${
                //   defaultSelectedKeys.includes(item.key) ? "active" : ""
                // }`}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content>
          <Outlet context={theme} />
        </Content>
      </Layout>
      <Footer />
    </>
  );
}

export default DashboardLayout;
