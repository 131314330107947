export const IS_PRODUCTION = 1;
export const BASE_URL =
  IS_PRODUCTION == 2
    ? "http://10.10.1.103:3002/api/v1/"
    : IS_PRODUCTION == 0
    ? "https://stage-api.triskel-cap.com/api/v1/admin/"
    : "https://api.triskel-cap.com/api/v1/admin/";
export const BASE_IMAGE_URL =
  IS_PRODUCTION == 0
    ? "https://stage-triskel.s3.ap-southeast-1.amazonaws.com"
    : "https://prod-triskel.s3.ap-northeast-2.amazonaws.com";
export const REACT_APP_DOMAIN_KEY = "2WjUF3XuPl11eLocbNoDc9ZmzvwtGrlM";

/************************************ Agent Admin APIs *****************************************************/

export const GET_PROPOSAL_LIST = "agent/proposalList";
export const GET_SECONDARY_PROPOSAL_LIST = "agent/secondaryProposalListAgent";

export const GET_INVESTED_REQUESTS_LIST = "agent/invested_particular_proposal";
export const GET_SECONDARY_INVESTED_REQUESTS_LIST =
  "agent/investedParticularSecondaryProposal";

export const GET_INVESTED_APP_LIST = "agent/getaddressBookListing";
export const GET_SECONDARY_ADDRESSES_LIST =
  "agent/getSecondaryAddressBookListing";

export const INVESTED_REQUESTS_APPROVE_REJECT = "agent/update_aprrove_proposal";
export const INVESTED_APP_REQUESTS_APPROVE_REJECT =
  "agent/activeInactiveAddressBook";

export const AGENT_LOGIN = "sto/admin/auth/login";
export const CHANGE_PASSWORD_AGENT = "sto/admin/auth/change_password";

export const DOWNLOAD_INVESTED_PROPOSAL_CSV =
  "agent/invested_particular_proposal_download_csv";

export const DOWNLOAD_INVESTED_APP_PROPOSAL_CSV =
  "agent/get_address_bookListing_download_csv";
//getSecondaryAddressBookDownloadCsv

export const DOWNLOAD_SECONDARY_INVESTED_PROPOSAL_CSV =
  "agent/investedSeconadryProposalDownloadCsv";

export const DOWNLOAD_SECONDARY_INVESTED_APP_PROPOSAL_CSV =
  "agent/getSecondaryAddressBookDownloadCsv";
