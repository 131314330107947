import React, { useState, useEffect } from "react";
import "./Login.scss";
import { Col, Row, Form } from "antd";
import InputCustom from "../../Ui/input/InputCustom.jsx";
import { ButtonCustom } from "../../Ui/button/ButtonCustom.jsx";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import LoginDetail from "../../Layout/LoginDetail/LoginDetail";
import Loader from "../../../Loader/Loader";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/_actions/LoginAction";
import { getData, saveData } from "../../../Services/Utils";
import * as Constants from "../../../constants/constants";
import { toast } from "../../Ui/Toast/Toast";
import TriskelLogo from "../../Assets/AdminImages/TriskelLogo.png";
import TriskalLoginDarkLogo from "../../Assets/AdminImages/TriskalLoginDarkLogo.png";
import { verify_2FA } from "../../../redux/_actions/googleAuthAction";
import ModalCommon from "../ModalCommon/ModalCommon";

let jwt = "";
function Login() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [code_2fa, setCode_2fa] = useState("");
  const theme = useOutletContext() || getData(Constants.APP_THEME);

  useEffect(() => {
    console.log("themelogin:::::", theme);
  }, [theme]);

  /************************************** LoginPress ****************************************************/
  const LoginPress = () => {
    if (email.trim().length == 0) {
      return toast.error("Enter email");
    } else if (!Constants.EMAIL_REGEX.test(email)) {
      return toast.error("Enter valid email");
    } else if (password.trim().length == 0) {
      return toast.error("Enter  password");
    } else if (password.trim().length < 3) {
      return toast.error("Enter valid password");
    }
    setLoading(true);
    const data = {
      email: email,
      password: password,
      // role: 6,
    };
    setTimeout(() => {
      dispatch(login({ data }))
        .then((res) => {
          setLoading(false);
          jwt = res.jwt_token;
          if (res.google2fa_status == 1) {
            setCode_2fa("");
            setIsModalOpen(true);
            return;
          } else {
            saveData(Constants.ACCESS_TOKEN, res.jwt_token);
          }
          if (res.admin_user_access_data) {
            if (res.admin_user_access_data?.status === 1) {
              localStorage.setItem(
                "loginId",
                res.admin_user_access_data?.admin_id
              );
              navigate("/resetpassword");
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err);
        });
    }, 100);
  };
  /**************************************showSwal****************************************************/
  const showSwal = () => {
    // window.location.reload()
    // Swal({
    //   icon: 'success',
    //   title: 'Congratulations!',
    //   text: 'You are successfully login to Triskel Admin.',
    //   color: '#d3d3d3',// '#7D80DF',
    //   allowEnterKey: false,
    //   closeOnEsc: false,
    //   closeOnClickOutside: false,
    // })
  };
  /**************************************handleCancel ****************************************************/
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  /************************************** btnClick ****************************************************/
  const btnClick = () => {
    if (code_2fa.trim().length < 6) {
      return toast.error("Enter valid 2FA Code");
    } else if (!Constants.NUMBER_REGEX.test(code_2fa)) {
      return toast.error("Enter valid 2FA Code");
    } else {
      // verify2FACode();
    }
  };
  /************************************** verify2FACode ****************************************************/
  // const verify2FACode = () => {
  //   setIsModalOpen(false);
  //   setLoading(true);
  //   saveData(Constants.ACCESS_TOKEN, jwt);
  //   setTimeout(() => {
  //     const data = {
  //       token: code_2fa,
  //     };
  //     dispatch(verify_2FA({ data }))
  //       .then((res) => {
  //         setLoading(false);
  //         showSwal();
  //       })
  //       .catch((err) => {
  //         toast.error(err);
  //         setLoading(false);
  //       });
  //   }, 100);
  // };
  return (
    <div className="flexAuto">
      <div className="loginScreen">
        <div className="container">
          <div>
            <Row>
              {/* ----------------------------------------------------------- */}
              <Col xs={24} md={12} className="loginScreen__leftCol">
                <LoginDetail
                  topimage={
                    <span>
                      {theme == "lightTheme" || theme == undefined ? (
                        <img src={TriskelLogo} width={300} />
                      ) : (
                        <img src={TriskalLoginDarkLogo} width={300} />
                      )}
                    </span>
                  }
                />
              </Col>
              {/* ----------------------------------------------------------- */}
              <Col xs={24} md={12} className="loginScreen__rytCol">
                <div className="loginScreen__loginLeft">
                  <div className="loginScreen__logInContact">
                    <h2>Triskel Account Login</h2>
                  </div>
                  {/* ----------------------------------------------------------- */}
                  <div className="loginScreen__inputSec">
                    <div className="loginScreen__innerInput">
                      <InputCustom
                        basicInput
                        inputCustum
                        label="Email"
                        placeholder={"Enter Email"}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        maxLength={100}
                      />
                    </div>
                    {/* ----------------------------------------------------------- */}
                    <div className="loginScreen__innerInput">
                      <InputCustom
                        passwordInput
                        inputCustum
                        eyeicon
                        label="Password"
                        placeholder={"Enter Password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        maxLength={100}
                      />
                      {/* ----------------------------------------------------------- */}
                      {/* <Link
                        to="/forgotpassword"
                        className="loginScreen__alink hover__link"
                      >
                        Forgot Password?
                      </Link> */}
                    </div>
                  </div>

                  <div className="loginScreen__iconBtn">
                    <ButtonCustom
                      onClick={() => LoginPress()}
                      yellowBtn
                      large
                      label="Login"
                      customClass="loginScreen__iconBtn__addPadd"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* ----------------------------------------------------------- */}
          <ModalCommon
            headTittle={"Enter 2FA"}
            isModalVisible={isModalOpen}
            handleCancel={handleCancel}
          >
            <div className="modalApp">
              <InputCustom
                placeholder={"Enter 2FA Code"}
                maxLength={6}
                basicInput
                label="2FA Code"
                inputCustum
                value={code_2fa}
                onChange={(e) => setCode_2fa(e.target.value)}
              />
              <div className="addnewSec">
                <ButtonCustom
                  label={"Done"}
                  yellowBtn
                  onClick={() => {
                    btnClick();
                  }}
                />
              </div>
            </div>
          </ModalCommon>
          {/* ----------------------------------------------------------- */}
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Login;
