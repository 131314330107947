import { Breadcrumb, Col, Grid, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./WhiteListAddresses.scss";
import WhiteListTab from "./WhiteListTab";
import { getInvestmentRequests, getUserData } from "../../../redux/_actions";
import { toast } from "../../Ui/Toast/Toast";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import DetailsCard from "../Dashboard/DetailsCard";

function WhiteListDetail() {
  const login = useSelector((state) => state?.LoginReducer?.userData);

  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState();
  const [consumedTokenQuantity, setConsumedTokenQuantity] = useState(0);
  const [usersInvested, setUsersInvested] = useState(0);
  const [whitelistedAddress, setWhiteListedAddress] = useState(0);
  const proposalName = location.state?.proposal_name;

  useEffect(() => {
    getProposalInvestmentRequests();
  }, []);

  /**************************************fetchUserData****************************************************/
  const getProposalInvestmentRequests = () => {
    const data = {
      proposal_id: location.state?.id,
      // type: type,
      page: 1,
      limit: 10,
    };
    setTimeout(() => {
      setLoading(true);
      dispatch(getInvestmentRequests({ data }))
        .then((response) => {
          const res = response.data.data;
          setConsumedTokenQuantity(
            res?.consumed_token_quantity === null
              ? 0
              : res?.consumed_token_quantity
          );
          setUsersInvested(
            res?.no_of_users_invested === null ? 0 : res?.no_of_users_invested
          );
          setWhiteListedAddress(
            res?.no_of_address_whitelisted === null
              ? 0
              : res?.no_of_address_whitelisted
          );

          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    }, 100);
  };

  return (
    <>
      <div className="breadcrumb assigned">
        <Breadcrumb>
          <Breadcrumb.Item>{proposalName}</Breadcrumb.Item>
        </Breadcrumb>
        {/* <span><b>Assigned Role : </b>{login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "Super Admin"}</span> */}
        <span>
          <b>Assigned Role : </b>
          {"Agent"}
        </span>
      </div>

      <div
        style={{ marginTop: userData ? 0 : -25 }}
        className="commonPadding userDatils"
      >
        <div style={{ marginTop: 20, marginBottom: 30 }}>
          <Row gutter={[25, 25]}>
            <Col
              className="responsive"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <div className="dashboardScreen1__reward">
                <DetailsCard
                  keyname={"Number of users Invested"}
                  Valname={usersInvested}
                />
              </div>
            </Col>
            <Col
              className="responsive"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <div className="dashboardScreen1__reward">
                <DetailsCard
                  keyname={"Number of addresses whitelisted"}
                  Valname={whitelistedAddress}
                />
              </div>
            </Col>
            <Col
              className="responsive"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <div className="dashboardScreen1__reward">
                <DetailsCard
                  keyname={"Consumed Token Quantity"}
                  Valname={consumedTokenQuantity}
                />
              </div>
            </Col>
          </Row>
        </div>

        <WhiteListTab state={{ user_id: location.state?.user_id }} />
      </div>
      <Loader isLoading={isLoading} />
    </>
  );
}

export default WhiteListDetail;
