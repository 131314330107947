import {
  LOGIN_API,
  REFERRAL_LIST_API,
  RESET_PASS_API,
  RESET_PASS_ADMIN_API,
  AGENT_LOGIN,
} from "../../Services/EndPoints";
import { apiCallPost, apiCallPostLogin } from "../../Services/ApiClient";
import { LOGOUT_USERS_PERSIST, USER_AUTH } from "./types";
import { encodeData } from "../../Services/ApiClient";
/************************************** Login ****************************************************/
export const login =
  ({ data }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      apiCallPostLogin(AGENT_LOGIN, data)
        .then((response) => {
          console.log("RESPONSE IN LOGIN", response);
          const res = response.data;
          //  console.log('res login::::::', res);
          res.google2fa_status != 1 &&
            res.admin_user_access_data?.status !== 1 &&
            setUserLoginSuccess(dispatch, res);
          resolve(res);
        })
        .catch((error) => {
          const errorMessage = error.message || error;
          //console.log('login er::::::', error);
          reject(errorMessage);
        });
    });
  };
/************************************** resetPassword ****************************************************/

/************************************** referral List ****************************************************/
const logoutApp =
  ({}) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      setUserLogoutSuccess(dispatch, "");
    });
  };
/************************************** setUserLoginSuccess ****************************************************/
export const setUserLoginSuccess = (dispatch, value) => {
  dispatch({
    type: USER_AUTH,
    payload: value,
  });
  //   console.log('setUserLoginSuccess ::::::', value);
};
/************************************** setUserLogoutSuccess ****************************************************/
export const setUserLogoutSuccess = (dispatch, value) => {
  dispatch({
    type: LOGOUT_USERS_PERSIST,
    // payload: value,
  });
  //  console.log('setUserLogoutSuccess ::::::', value);
};
/************************************** resetPasswordforadmin ****************************************************/
