import { apiCallGet, apiCallPost } from "../../Services/ApiClient";
import { CHECK_PERMISSIONS, USERS_CHART_API, USERS_COUNT_API } from "../../Services/EndPoints";
import commonError from "../../Components/Common/Index";
import { LOGOUT_USERS_PERSIST, USER_AUTH } from "./types";

/************************************** userChart ****************************************************/

/************************************** userCount ****************************************************/

/************************************** userCount ****************************************************/
// export const checkPermissions = ({ }) => {
//      return dispatch => {
//           return new Promise((resolve, reject) => {
//                apiCallGet(CHECK_PERMISSIONS).then(response => {
//                     const res = response.data;
//                     setUserLoginSuccess(dispatch, res)
//                     //console.log('res userCount::::::', res);
//                     resolve(res);
//                }).catch(error => {
//                     if (error?.message == 'Unable to decode data.') {
//                          commonError();
//                     } else {
//                          const errorMessage = error.message || error;
//                          //console.log('userCount er::::::', error);
//                          reject(errorMessage);
//                     }
//                });
//           });
//      };
// };
/************************************** setUserLoginSuccess ****************************************************/
export const setUserLoginSuccess = (dispatch, value) => {
     dispatch({
               type: USER_AUTH,
               payload: value,
     });
     //   console.log('setUserLoginSuccess ::::::', value);
};