import { Button } from "antd";
import React from "react";
import "./ButtonCustomStyle.scss";

const ButtonCustom = (props) => {
  const {
    garyBtn,
    lightBtn,
    yellowBtn,
    lessPadding,
    leftIcon,
    label,
    onlyIcon,
    rightIcon,
    medium,
    large,
    black,
    customClass,
    lytgaryBtn,
    orderBtn,
    disabled,
    onClick,
  } = props;
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={`btnCustom ${garyBtn ? "garyBtn" : ""}${
        lytgaryBtn ? "lytgaryBtn" : ""
      } ${lightBtn ? "lightBtn" : ""}${yellowBtn ? "yellowBtn" : ""}  ${
        orderBtn ? "orderBtn" : ""
      } ${lessPadding ? "lessPadding" : ""} ${medium ? "medium" : ""}${
        black ? "black" : ""
      } ${large ? "large" : ""} ${customClass}`}
      {...props}
    >
      {leftIcon && <span className="leftIcon">{leftIcon}</span>}
      {label && <span className="fontFamilyText">{label}</span>}
      {onlyIcon && <span className="onlyIcon">{onlyIcon}</span>}
      {rightIcon && <span className="rightIcon">{rightIcon}</span>}
    </Button>
  );
};

export { ButtonCustom };
