import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from "antd";
import { Link } from "react-router-dom";
import { Drawer } from "antd";
import {
  CloseIcon,
  DarkModeIcon,
  LightModeIcon,
  ToggleIcon,
} from "../../Assets/Images/SvgImgs";
import RightNavbar from "./RightNavbar";
import "./Header.scss";
import { SettingOutlined } from "@ant-design/icons";
import TriskelLogo from "../../Assets/AdminImages/logo negro.png";
import LogoDarkmode from "../../Assets/AdminImages/LogoDarkmode.png";
import { getData, saveData } from "../../../Services/Utils";
import * as Constants from "../../../constants/constants";
import { useSelector } from "react-redux";

function Header({ changeTheme }) {
  const { useBreakpoint } = Grid;
  const { lg, sm } = useBreakpoint();
  const isLogin = useSelector((state) => state?.LoginReducer?.userData);
  const [theme, settheme] = useState(getData(Constants.APP_THEME));

  useEffect(() => {
    if (theme) {
      if (theme === "darkTheme") {
        document.body.classList.remove("lightTheme");
        document.body.classList.add("darkTheme");
      } else {
        document.body.classList.remove("darkTheme");
        document.body.classList.add("lightTheme");
      }
    }
  }, [theme]);

  useEffect(() => {
    const themes = getData(Constants.APP_THEME);
    if (themes && !theme) {
      settheme(themes);
    } else if (!themes && !theme) {
      saveData(Constants.APP_THEME, "lightTheme");
    }
  }, []);

  useEffect(() => {
    if (theme) {
      saveData(Constants.APP_THEME, theme);
    }
  }, [theme]);

  const toggleTheme = () => {
    if (theme === "darkTheme") {
      settheme("lightTheme");
      changeTheme("lightTheme");
      document.body.classList.add("lightTheme");
      document.body.classList.remove("darkTheme");
    }
    if (theme === "lightTheme") {
      settheme("darkTheme");
      changeTheme("darkTheme");

      document.body.classList.add("darkTheme");
      document.body.classList.remove("lightTheme");
    }
  };

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };

  const settingExchangeToggle = () => {
    document.body.classList.toggle("settingExchangeToggle");
  };
  return (
    <Row className="header">
      <Col className="header__left">
        <Link to="/whiteListAddresses" className="header__left__logoBrand">
          {theme === "lightTheme" ? (
            <img src={TriskelLogo} alt="Logo" width={100} />
          ) : (
            <img src={LogoDarkmode} alt="Logo" width={100} />
          )}
        </Link>
        {/* {lg && <LeftNavbar />} */}
      </Col>
      <Col className="header__right">
        {isLogin ? lg && <RightNavbar /> : null}
        <button className="header__themeToggleIcon" onClick={toggleTheme}>
          {theme === "darkTheme" ? (
            <span className="header__fixWidthIcon">
              <LightModeIcon />
            </span>
          ) : (
            <span className="header__fixWidthIcon">
              <DarkModeIcon />
            </span>
          )}
        </button>
        <div className="header__settingIconOuter">
          <button
            onClick={settingExchangeToggle}
            className="header__settingIconStyle"
          >
            <SettingOutlined
              className="settingIcon"
              style={
                theme === "darkTheme" ? { color: "#fff" } : { color: "#000" }
              }
            />
          </button>
        </div>
        {!lg && (
          <div className="header__toggleStyle" onClick={showDrawer}>
            <span className="header__iconStyles header__toggleStyle__icon">
              <ToggleIcon />
            </span>
          </div>
        )}
      </Col>

      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="header__drawer"
        width={sm ? "378px" : "300px"}
      >
        <div
          className="header__iconStyles header__drawer__icons"
          onClick={onClose}
        >
          <CloseIcon />
        </div>
        {/* <LeftNavbar /> */}
        <RightNavbar />
      </Drawer>
    </Row>
  );
}

export default Header;
