import "./App.scss";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Mainlayout from "./Components/Layout/Mainlayout/Mainlayout";
import Login from "./Components/Pages/Login/Login";
import DashboardLayout from "./Components/Layout/DashboardLayout/DashboardLayout";
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import AdminControls from "./Components/Pages/AdminControls/AdminControls";
import {
  clearUserCookie,
  deleteAllCookies,
  getData,
  saveData,
} from "./Services/Utils";
import * as Constants from "../src/constants/constants";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "./Components/Ui/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { logoutApp } from "./redux/_actions";
// import CardStatus from "./Components/Pages/CardStatus/CardStatus.jsx";
// import CardDetails from "./Components/Pages/CardDetails/CardDetails.jsx";
// import CardControl from "./Components/Pages/CardControl/CardControl";
import { REACT_APP_DOMAIN_KEY } from "./Services/EndPoints";

import { checkPermissions } from "./redux/_actions/dashboardAction";
import WhiteListAddresses from "./Components/Pages/WhiteListAddresses/WhiteListAddresses.jsx";
import WhiteListDetail from "./Components/Pages/WhiteListAddresses/WhiteListDetail.jsx";
import SecondaryWhitelistDetail from "./Components/Pages/WhiteListAddresses/SecondaryWhitelistDetail";
// import MintingAddresses from "./Components/Pages/MintingAddresses/MintingAddresses";
// import MintingDetail from "./Components/Pages/MintingAddresses/MintingDetail";

function App() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state?.LoginReducer?.userData);
  // let tab_data = login?.admin_user_access_data?.access ? JSON.parse(login?.admin_user_access_data?.access) : "";
  // let role_name = login?.admin_user_access_data ? login?.admin_user_access_data?.role_name : "super_admin";
  // console.log('chk login11111:::::::', login)

  const onIdle = () => {
    console.log("Idle");
    logout();
  };
  /************************************** confirm Logout ****************************************************/
  const logout = async () => {
    const theme = await getData(Constants.APP_THEME);
    let urlArray = window.location.href.split("/");
    if (urlArray[urlArray.length - 1] === "new_admin") {
      toast.error("Session Expired");
    }
    clearUserCookie("csrfToken", "", 0);
    sessionStorage.clear();
    localStorage.clear();
    deleteAllCookies();
    saveData(Constants.APP_THEME, theme);
    // dispatch(logoutApp({}));
    //navigate("/");
    window.location.replace("/" + REACT_APP_DOMAIN_KEY);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 10,
    promptBeforeIdle: 1000 * 30,
  });
  useEffect(() => {
    const themes = getData(Constants.APP_THEME);
    console.log("themes::::::", themes);
    themes == "darkTheme"
      ? document.body.classList.add("darkTheme")
      : document.body.classList.add("lightTheme");
    let urlArray = window.location.href.split("/");
    if (urlArray[urlArray.length - 1] === "new_admin") {
      logout();
    }
    if (login) {
      // checkAuthorization();
    }
  }, []);
  // const checkAuthorization = () => {
  //   setTimeout(() => {
  //     dispatch(checkPermissions({}))
  //       .then((res) => {
  //         console.log("checkAuthorization", res);
  //       })
  //       .catch((err) => {
  //         if ("Unable to decode data.") {
  //           toast.error("User does not exist");
  //           logout_on_deleted();
  //         } else {
  //           toast.error(err);
  //         }
  //       });
  //   }, 100);
  // };
  return (
    <Routes>
      {login ? (
        <Route path="/" element={<DashboardLayout />}>
          <Route index path="/" element={<WhiteListAddresses />} />
          {/* <Route
            index
            path="/mintingAddresses"
            element={<MintingAddresses />}
          />
          <Route
            index
            path="mintingAddresses/mintingDetails"
            element={<MintingDetail />}
          /> */}
          <Route
            index
            path="whiteListAddresses"
            element={<WhiteListAddresses />}
          />

          <Route
            index
            path="whiteListAddresses/whiteListDetail"
            element={<WhiteListDetail />}
          />
          <Route
            index
            path="whiteListAddresses/SecondaryWhiteListDetail"
            element={<SecondaryWhitelistDetail />}
          />

          <Route index path="adminControls" element={<AdminControls />} />
        </Route>
      ) : (
        <Route path="/" element={<Mainlayout />}>
          <Route index path="/" element={<Login />} />

          <Route index path="/new_admin" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      )}
    </Routes>
  );
}

export default App;

export const logout_on_deleted = async () => {
  clearUserCookie("csrfToken", "", 0);
  sessionStorage.clear();
  localStorage.clear();
  deleteAllCookies();
  window.location.replace("/" + REACT_APP_DOMAIN_KEY);
  toast.error("Session expired!");
};
